export default {
  chart: {
    primary: '#39BA9B',
    primaryLighter: '#39BA9B88',
    primaryShade: [
      '#39BA9B', '#39BA9Bcc', '#39BA9B99', '#39BA9B77',
    ],
  },
  pieChart: ['#ED3869', '#FFDC00'],
}
